import {
  LocationType,
  SlotAvailability,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import {
  CalendarState,
  TFunction,
} from '../../components/BookingCalendar/controller';
import { CalendarContext } from '../context/contextFactory';
import {
  CalendarErrors,
  Preference,
  SelectedVariantOptions,
} from '../../types/types';
import { getSlotDuration } from '../duration/duration';
import {
  getFormattedPrice,
  isServiceVariantWithCustom,
  isServiceVariantWithStaff,
} from '../dynamicPricing/dynamicPricing';
import {
  getServiceMaxParticipantsPerBooking,
  isServiceVariedPricing,
} from '@wix/bookings-calendar-catalog-viewer-mapper';

export const PreferencesErrors: CalendarErrors[] = [
  CalendarErrors.NO_SELECTED_LOCATION_ERROR,
  CalendarErrors.NO_SELECTED_DURATION_ERROR,
  CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
  CalendarErrors.NO_SELECTED_CUSTOM_PREFERENCE_ERROR,
];

export type BookingsPreferenceError = {
  key: CalendarErrors;
  message: string;
};

export type SelectedBookingPreference = {
  key: Preference;
  value: string;
  numberOfParticipants?: number;
  isMultipleChoices?: boolean;
};

export type BookingPreferenceOption = {
  id?: string;
  value?: string;
  subtitle?: string;
  ariaLabel?: string;
  isSelectable?: boolean;
  numberOfParticipants?: number;
  isWithWaitingList?: boolean;
};

export type BookingPreference = {
  key: Preference;
  id?: string;
  error: BookingsPreferenceError;
  isMultipleChoices?: boolean;
  options: BookingPreferenceOption[];
  openSpotsRemained?: number;
  note?: string;
  disabled?: boolean;
  preselectedOptionId?: BookingPreferenceOption['id'];
  placeholder: string;
  getBookingPreferenceOptionFromSlot?: (
    slotAvailability: SlotAvailability,
  ) => BookingPreferenceOption;
  getBookingPreferenceOptionsFromSelectedVariantsOptions?: (
    selectedVariantsOptions: SelectedVariantOptions[],
  ) => BookingPreferenceOption[];
  label?: string;
};

export const getBookingPreferences = ({
  context,
  state,
}: {
  context: CalendarContext;
  state: CalendarState;
}): BookingPreference[] => {
  const { getContent, t, businessInfo, settingsParams } = context;
  const { servicesInView, serviceVariantsMap, selectedTime } = state;
  const service = servicesInView[0];
  const serviceVariants = serviceVariantsMap[service.id!];

  const locationLabel = getContent({
    settingsParam: settingsParams.locationLabel,
    translationKey: 'app.settings.defaults.location-label',
  });
  const location: BookingPreference = {
    key: Preference.LOCATION,
    error: {
      key: CalendarErrors.NO_SELECTED_LOCATION_ERROR,
      message: t('app.booking-details.dropdowns.error.location.text'),
    },
    placeholder: locationLabel,
    options: [],
    label: t('app.booking-details.dropdowns.label', {
      paramName: locationLabel,
      required: true,
    }),
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const locationId = slotAvailability!.slot!.location!.id;
      const locationText = getLocationText(slotAvailability!.slot!.location, t);

      return {
        id: locationId || locationText!,
        value: locationText!,
      };
    },
  };

  const staffMemberLabel = getContent({
    settingsParam: settingsParams.staffMemberLabel,
    translationKey: 'app.settings.defaults.staff-member-label',
  });

  const staffMember: BookingPreference = {
    key: Preference.STAFF_MEMBER,
    error: {
      key: CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
      message: t('app.booking-details.dropdowns.error.staff-member.text'),
    },
    placeholder: staffMemberLabel,
    options: [],
    label: t('app.booking-details.dropdowns.label', {
      paramName: staffMemberLabel,
      required: true,
    }),
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ): BookingPreferenceOption => {
      const staffMemberName = slotAvailability.slot?.resource?.name!;
      const staffMemberId = slotAvailability.slot?.resource?.id!;
      let formattedPrice;
      if (
        isServiceVariedPricing(service) &&
        serviceVariants?.options?.values?.length === 1 &&
        isServiceVariantWithStaff(serviceVariants)
      ) {
        const optionId = serviceVariants.options.values[0].id!;
        formattedPrice = getFormattedPrice({
          state,
          context,
          service,
          choiceId: staffMemberId,
          optionId,
        });
      }
      return {
        id: staffMemberId,
        value: staffMemberName,
        subtitle: formattedPrice,
      };
    },
  };

  const durationLabel = getContent({
    settingsParam: settingsParams.durationLabel,
    translationKey: 'app.settings.defaults.duration-label',
  });

  const duration: BookingPreference = {
    key: Preference.DURATION,
    error: {
      key: CalendarErrors.NO_SELECTED_DURATION_ERROR,
      message: t('app.booking-details.dropdowns.error.duration.text'),
    },
    placeholder: durationLabel,
    options: [],
    label: t('app.booking-details.dropdowns.label', {
      paramName: durationLabel,
      required: true,
    }),
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const rfcStartTime = slotAvailability.slot?.startDate!;
      const rfcEndTime = slotAvailability.slot?.endDate!;
      const slotDuration = getSlotDuration({
        rfcStartTime,
        rfcEndTime,
        t,
        dateRegionalSettingsLocale: businessInfo!.dateRegionalSettingsLocale!,
      });

      return {
        id: slotDuration.durationText,
        value: slotDuration.durationText,
        ariaLabel: slotDuration.durationAriaText,
      };
    },
  };

  const customPreferences: BookingPreference[] = [];
  if (serviceVariants && selectedTime) {
    const maxParticipantsPerBook = getServiceMaxParticipantsPerBooking(service);
    const isMultipleChoices =
      !!maxParticipantsPerBook && maxParticipantsPerBook > 1;

    if (isServiceVariantWithCustom(serviceVariants)) {
      const variantData: {
        optionId: string;
        label: string;
        choices: string[];
      } = {
        label: serviceVariants.options?.values?.[0].customData?.name!,
        optionId: serviceVariants.options?.values?.[0].id!,
        choices: serviceVariants.variants?.values?.map(
          (variant) => variant.choices?.[0].custom!,
        )!,
      };

      customPreferences.push({
        key: Preference.CUSTOM,
        id: variantData.optionId,
        placeholder: t(
          'app.booking-details.dropdowns.custom-preferences.label.text',
          {
            labelName: variantData.label,
          },
        ),
        error: {
          key: CalendarErrors.NO_SELECTED_CUSTOM_PREFERENCE_ERROR,
          message: t(
            'app.booking-details.dropdowns.error.custom-preference.text',
            {
              paramName: variantData.label,
            },
          ),
        },
        label: t('app.booking-details.dropdowns.label', {
          paramName: variantData.label,
          required: true,
        }),
        isMultipleChoices,
        getBookingPreferenceOptionsFromSelectedVariantsOptions: (
          selectedVariantsOptions,
        ) => {
          return (
            variantData.choices.map((choice) => ({
              id: choice,
              value: choice,
              subtitle: getFormattedPrice({
                state,
                context,
                optionId: variantData.optionId,
                choiceId: choice,
                service,
              }),
              numberOfParticipants:
                selectedVariantsOptions.find(({ choices }) =>
                  choices.some(
                    ({ optionId, custom }) =>
                      optionId === variantData.optionId && custom === choice,
                  ),
                )?.numberOfParticipants || 0,
            })) || []
          );
        },
        options: [],
      });
    }
  }

  return [location, staffMember, duration, ...customPreferences];
};

export const getLocationText = (location: any, t: TFunction): string => {
  switch (location?.locationType) {
    case LocationType.OWNER_BUSINESS:
      return location.name;
    case LocationType.OWNER_CUSTOM:
      return location.formattedAddress;
    case LocationType.CUSTOM:
      return t('app.booking-details.dropdowns.locations.client-place.text');
    default:
      return '';
  }
};
